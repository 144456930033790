<template>
  <div>
    <v-row>
      <v-col
        v-for="(item, index) in dashboardItems"
        :key="index"
        cols="12"
        sm="3"
        md="3"
        lg="3"
      >
        <v-card
          :to="item.path"
          flat
          rounded="lg"
        >
          <v-card-title
            primary-title
            class="d-flex justify-center"
          >
            <img
              :src="$_get_image(item.image)"
              width="45"
            >
            <div class="ps-3">
              {{ $t(item.title) }}
            </div>

            <!-- <v-spacer /> -->
            <!-- <v-icon>
              {{ $vuetify.rtl ? 'mdil-arrow-left' : 'mdil-arrow-right' }}
            </v-icon> -->
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    drawer: { type: Boolean, default: true },
  },

  data() {
    return {
      dashboardItems: [
        {
          title: 'Companies',
          image: 'company.png',
          path: '/admin-panel/company',
          permission: 'view_company',
        },
        // {
        //   title: 'Shareholders',
        //   image: 'shareholder.png',
        //   path: '/shareholder',
        //   permission: 'view_shareholder',
        // },
        // {
        //   title: 'Attachments',
        //   image: 'photo-gallery.png',
        //   path: '/attachment',
        //   permission: 'view_attachment',
        // },
      ]
    }
  },

  // methods: {
  //   getImage(img) {
  //     return require(`/src/assets/images/${img}`)
  //   }
  // },
}
</script>

<style lang="scss" scoped>
</style>
